<template>
    <div>
        <v-breadcrumbs :items="crumbs" divider="/" style="background-color: #f0f0f0">
        </v-breadcrumbs>
        <v-card class="mx-auto" outlined tile color="#F8F8FF">
        <v-card-title>Duit Now</v-card-title>
        <v-container fluid>
            <v-row>
                <!--
                <v-col cols="12" md="6"><v-btn v-if="validateAccess('system_add')" :loading="btn_loading" :disabled="btn_loading" tile type="submit" small color="primary" @click="viewMore(1, 'create')"><v-icon left x-small dark>fa fa-cogs</v-icon>Create System
                    <template v-slot:loader>
                        <span>Loading...</span>
                    </template>
                    </v-btn>
                </v-col>
                -->
                <v-btn-toggle class="col-12 offset-md-6 col-md-6" style="background: none">
                    <v-btn small disabled>
                        <v-icon small>fa fa-search</v-icon>
                    </v-btn>
                    <v-text-field placeholder="Search" class="search-textfield" hide-details outlined dense v-model="search"></v-text-field>
                </v-btn-toggle>
            </v-row>
            <v-row>
                <v-col cols="12" md="12">
                    <v-autocomplete
                    dense
                    outlined
                    placeholder="All Virtual Account"
                    label="Virtual Account"
                    v-model="merchantcode.selected"
                    @change="execFilter('merchantcode')"
                    :items="merchantcode.items"
                    item-text="name"
                    item-value="MerchantCode"
                    :rules="merchantcode.rule"
                    :loading="merchantcode.loader"
                    :disabled="merchantcode.disable"
                    hide-details
                    >
                    </v-autocomplete>
                </v-col>
                <v-col cols="12" md="12">
                    <vc-date-picker
                    :disabled-dates='dayplusone'
                     :key="counter1" v-model="starttime.item" mode="date" is-range update-on-input :popover="{visibility: 'click'}" @drag="cleardayplusone($event)" @input="listItems(1)" :columns="$screens({ default: 1, lg: 2 })" :masks="{ input: 'DD-MM-YYYY' }">
                      <template v-slot="{ inputValue, inputEvents, togglePopover }">
                            <v-text-field
                                @click="togglePopover({ placement: 'bottom-start' })"
                                :value="inputValue.start != null ? `${inputValue.start} - ${inputValue.end}` : ''"
                                @keyup="starttime.item.start = ''; starttime.item.end = ''"
                                dense
                                v-on="inputEvents"
                                hide-details="auto"
                                label="From Date - To Date"
                                outlined
                                autocomplete="off"
                                hint="You can only select date range not more than 1 month."
                                persistent-hint
                            ></v-text-field>
                        </template>
                      </vc-date-picker>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" md="8">
                    <v-pagination
                    v-model="page"
                    :length.sync="pages"
                    :total-visible="6"
                    class="mt-4"
                    style="float: left !important"
                    ></v-pagination><br/><p class="text-caption" style="margin-left: 10px">Page {{page}} of {{pages}}</p>
                </v-col>
            </v-row>
            <v-row dense>
                <v-col><span class="text-body-2">View {{page > 1 ? (page - 1) * 100 + 1 : 1}} - {{getCount}} of {{totalrecords}}</span></v-col>
                <v-col cols="12" md="12" id="table-parent">
                    <div id="wrapper1">
                        <div id="div1">
                        </div>
                    </div>
                    <v-data-table
                        calculate-widths
                        :headers="headers"
                        :items="items"
                        hide-default-footer
                        :loading="tableloading"
                        loading-text= "Loading Data... Please wait"
                        :items-per-page="100"
                        id="main-table"
                        dense
                    >
                    <template slot="item" slot-scope="props">
                        <tr>
                            <td>{{page > 1 ? (page - 1) * 100 + props.index + 1 : props.index + 1}}</td>
                            <td>{{props.item.MerchantTrxRefno}}</td>
                            <td>{{props.item.PaymentProvider3rdPartyRefNo}}</td>
                            <td>
                                <v-tooltip top v-if="props.item.PassRef !== null">
                                <template v-slot:activator="{ on }">
                                <a style="text-decoration: underline" id="custom-hover" v-on="on" @click.prevent="viewMore(props.item, 'view_details')">{{props.item.PassRef}}</a>
                                </template>
                                <span>Details</span>
                                </v-tooltip>
                                <span v-else>N/A</span>
                            </td>
                            <td>{{props.item.SPPAY_QRID}}</td>
                            <td>{{props.item.SPPAY_SourceOfFundCode}}</td>
                            <td style="white-space: pre">{{props.item.SPPAY_SourceOfFundName}}</td>
                            <td>{{props.item.SPPAY_CarParkID}}</td>
                            <td style="white-space: pre">{{props.item.CarParkName}}</td>
                            <td>{{props.item.SPPAY_SenderName}}</td>
                            <td>{{props.item.SPPAY_RequestCreatedDateTime}}</td>
                            <td>{{props.item.Product}}</td>
                            <td>{{props.item.Amount}}</td>
                        </tr>
                    </template>
                    </v-data-table>
                </v-col>
            </v-row>
        </v-container>
        </v-card>
        <v-snackbar
        v-model="swal.notification"
        top
        :color="swal.scolor"
        >
            {{ swal.message }}
        </v-snackbar>
        <viewpdf :dialog="ma.modal_pdf" :customText="ma.customText" :modalInfo="ma.modalInfo" :pdfurl="pdfurl" @closed="onClosed"></viewpdf>
        <viewdetails :dialog="ma.modal_viewdetails" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" :readonly="ma.readonly" :allInfo="ma.allInfo" @confirm="onConfirm" @closed="onClosed"></viewdetails>
        <!--
        <create :dialog="ma.modal" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" :allInfo="ma.allInfo" @confirm="onConfirm" @closed="onClosed"></create>
        <alert :dialog="ma.modal_status" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" @confirm="onConfirm" @closed="onClosed"></alert>
        <alertbox2 :dialog="ex.modal" @closed="onClosed" :content="ex.content"></alertbox2>
        -->
    </div>
</template>
<script>
import timer from '../../assets/js/sleeper';
import create from '.././modals/CreateSystem';
import alert from '.././modals/Alert';
import {ERR} from '../../assets/js/errhandle.js';
import alertbox2 from '../modals/Notification';
import * as moment from "moment";
import * as tz from "moment-timezone";
import {rule} from '../../assets/js/checkPerm';
import viewpdf from '.././modals/ModalPDF2';
import viewdetails from '.././modals/ViewDuitNowDetails';

export default {
    name: 'Duit Now',
    title: 'Duit Now',
    
    data()  {
        return {
            crumbs: [
                {
                text: 'Home',
                disabled: true,
                href: '#',
                },

                {
                text: 'Transaction',
                disabled: true,
                href: '#',
                },

                {
                text: 'Payment Gateway',
                disabled: true,
                href: '#',
                },

                {
                text: 'Duit Now',
                disabled: true,
                href: '#',
                }
            ],
            items: [],
            search: '',
            /*
            #, Ref. No., Transaction ID, Trxn Ref., QR ID, Payment Code, Payment Method, CP ID, Car Park, Sender Name, Transaction Date, Product, Amount (RM)
            */
            headers: [
                { text: '#', value: 'row', width: '3%' },
                { text: 'Ref. No.', value: 'iPay88FPXDirectRefNo', width: '3%' },
                { text: 'Transaction ID', value: 'PaymentProvider3rdPartyRefNo', width: '3%' },
                { text: 'Trxn Ref.', value: 'PassRef', width: '3%' },
                { text: 'QR ID', value: 'SPPAY_MerchantCode', width: '3%' },
                { text: 'Payment Code', value: 'SPPAY_PaymentOptionName', width: '3%' },
                { text: 'Payment Method', value: 'SPPAY_PaymentOptionName', width: '10%' },
                { text: 'CP ID', value: 'SPPAY_CarParkID', width: '3%' },
                { text: 'Car Park', value: 'CarParkName', width: '5%' },
                { text: 'Sender Name', value: 'name', width: '3%' },
                { text: 'Transaction Date', value: 'SPPAY_RequestCreatedDateTime', width: '5%', sort: function (a, b) {
                    let aa = moment(a, 'DD-MM-YYYY hh:mm:ss A');
                    let aa2 = aa.toDate();

                    let bb = moment(b, 'DD-MM-YYYY hh:mm:ss A');
                    let bb2 = bb.toDate();

                    return aa2 > bb2 ? -1 : aa2 < bb2 ? 1 : 0;
                }  },
                { text: 'Product', value: 'Product', width: '5%' },
                { text: 'Amount (RM)', value: 'Amount', width: '5%' },
            ],
            tableloading: false,
            btn_loading: false,
            pages: 0,
            page: 1,
            swal: {
                notification: false,
                message: '',
                scolor: '',
            },
            ma: {
                modal: false,
                modal_action_type: '',
                modalInfo: [],
                customText: [],
                confirmCreate: false,

                modal_status: false,
                modal_update: false,
                modal_viewdetails: false,
                readonly: true,
                allInfo: [],

                modal_pdf: false,
            },
            pdfurl: '',

            ex: {
                modal: false,
                content: {
                    headline: '',
                    icon: '',
                    chipColor: '',
                    iconColor: '',
                    maintext: '',
                    smalltext: ''
                }
            },

            merchantcode: {
                items: [],
                itemsFull: [],
                selected: '',
                loader: false,
                disable: false,
                rule: []
            },
            operator: {
                items: [],
                itemsFull: [],
                selected: '',
                loader: false,
                disable: false,
                rule: []
            },
            carpark: {
                items: [],
                itemsFull: [],
                selected: '',
                loader: false,
                disable: false,
                rule: []
            },
            displaystatus: {
                counter: 0,
                selected: null,
                rerender: 0,
                label: 'All Mobile Display Status'
            },
            toggle_exclusive_search: 0,
            totalrecords: '',
            starttime: {item: {start: '', end: ''}},
            starttimeforapi: '',
            endtimeforapi: '',
            dayplusone: [],
            counter1: 0,
        }
    },

    components: {create, alert, alertbox2, viewpdf, viewdetails},

    async created(){
        this.getMerchantCodeFull();
        await this.getOperatorFull();
        await this.getCarParkFull();
        this.listItems(1);
        this.horizontalScroll();
    },

    watch: {
        page: {
        handler: function(value) {
            this.items = [];
            this.listItems();
        }
        },

        'search': {
        handler: function(value) {
            this.searchTimeOut();
        }
        },

        'toggle_exclusive_search': {
        handler: function(value) {
            if (this.search != '')
            this.searchTimeOut();
        }
        },
    },

    computed: {
        getCount(){
            if (this.items.length > 0)
                return this.items[this.items.length - 1].row;

                else{
                    return '';
                }
            },
    },

    methods: {
        async listItems(pg){
            try{
                this.tableloading = true;
                await this.setTime();
                this.counter1++;
                let response = await this.$store.dispatch("getDuitNowTransaction", {page: pg > 0 ? this.page = pg : this.page, search: this.search, transactionstartdate: this.starttimeforapi, transactionenddate: this.endtimeforapi, merchantcode: this.merchantcode.selected });

                if (response.data.code == 'AP000'){
                    this.$vuetify.goTo(0);

                let dt = response.data.record;
                let recordCount = response.data.count;
                this.totalrecords = recordCount;

                for(var i=0; i<dt.length; i++){
                    dt[i].row = this.page > 1 ? (this.page - 1) * 100 + i + 1 : i + 1;
                    dt[i].SPPAY_RequestCreatedDateTime = moment(dt[i].SPPAY_RequestCreatedDateTime).format('DD-MM-YYYY hh:mm:ss A');
                    dt[i].Amount = Number(dt[i].Amount).toFixed(2);

                    dt[i].CarParkName = dt[i].CarParkName == null ? "N/A" : dt[i].CarParkName;
                    dt[i].SPPAY_CCName = dt[i].SPPAY_CCName == null || dt[i].SPPAY_CCName == "" ? "N/A" : dt[i].SPPAY_CCName;
                    dt[i].TransactionParkingPayment_TicketID = dt[i].TransactionParkingPayment_TicketID || "N/A";
                    dt[i].ParkingWheelLockID = dt[i].ParkingWheelLockID || "N/A";

                    dt[i].Product = dt[i].Product == "WheelLock Parking" ? 'BayLock Parking' : dt[i].Product;
                    //replace(/\s/g,'') remove any literal whitespace

                }
                
                this.items = dt;
                this.pages = Math.ceil(recordCount / 100);
                this.tableloading = false;
                }
            }

            catch(err){
                console.log(err);
            }
        },

        async getMerchantCodeFull(){
            let list = [];
            this.merchantcode.items = [];
            this.merchantcode.selected = "";

            try{
                this.merchantcode.loader = true;
                this.merchantcode.disable = true;

                let self = this;
                let response = await this.$store.dispatch("getQRID", {});
                let dt = response.data.record;
                if (response.data.code == 'AP000'){
                    this.merchantcode.items.push({name: 'All Virtual Account', MerchantCode: ""});
                    this.merchantcode.itemsFull.push({name: 'All Virtual Account', MerchantCode: ""});

                for(var i=0; i<dt.length; i++){
                    this.merchantcode.items.push({name: `${dt[i].VirtualAccName} - ${dt[i].QRID}`, MerchantCode: dt[i].QRID});
                    this.merchantcode.itemsFull.push(dt[i]);
                }

                this.merchantcode.selected = "";
                }

                //this.operator.itemsFull = dt;

                this.merchantcode.loader = false;
                this.merchantcode.disable = false;
            }

            catch(err){
                console.log(err);
            }

            return list;
        },

        async getOperatorFull(){
            let list = [];
            this.operator.items = [];
            this.operator.selected = "";

            try{
                this.operator.loader = true;
                this.operator.disable = true;

                let self = this;
                let response = await this.$store.dispatch("listOperator", {search: ''});
                let dt = response.data.record;
                if (response.data.code == 'AP000'){
                    this.operator.items.push({name: "All Operators", OperatorID: ""});
                    this.operator.itemsFull.push({name: "All Operators", OperatorID: ""});

                for(var i=0; i<dt.length; i++){
                    if (dt[i].SystemID === 2){
                        dt[i].name = `${dt[i].OperatorID} - ${dt[i].OperatorName}`;
                        this.operator.items.push({name: dt[i].name, OperatorID: dt[i].OperatorID});
                        this.operator.itemsFull.push(dt[i]);
                    }
                }

                this.operator.selected = this.operator.items[0].OperatorID;
                }

                //this.operator.itemsFull = dt;

                this.operator.loader = false;
                this.operator.disable = false;
            }

            catch(err){
                console.log(err);
            }

            return list;
        },

        async getCarParkFull(){
            let list = [];
            this.carpark.items = [];
            this.carpark.selected = "";

            try{
                this.carpark.loader = true;
                this.carpark.disable = true;

                let self = this;

                if (this.operator.selected){
                    let carpark = this.carpark.itemsFull.filter(item => {
                    return item.OperatorID == self.operator.selected;
                    });

                    this.carpark.items.push({name: "All Car Parks", CarParkID: ""});

                    for(var i=0; i<carpark.length; i++){
                        carpark[i].name = `${carpark[i].CarParkID} - ${carpark[i].CarParkName}`;
                        this.carpark.items.push({name: carpark[i].name, CarParkID: carpark[i].CarParkID});
                    }

                    //this.operator.selected = this.operator.items[1].OperatorID;
                }

                else{
                    let response = await this.$store.dispatch("getAllCarpark2", {});
                    let dt = response.data.record;
                    if (response.data.code == 'AP000'){
                        this.carpark.items.push({name: "All Car Parks", CarParkID: ""});
                        this.carpark.itemsFull.push({name: "All Car Parks", CarParkID: ""});

                    for(var i=0; i<dt.length; i++){
                        dt[i].name = `${dt[i].CarParkID} - ${dt[i].CarParkName}`;
                        this.carpark.items.push({name: dt[i].name, CarParkID: dt[i].CarParkID});
                        this.carpark.itemsFull.push(dt[i]);
                    }

                    this.carpark.selected = this.carpark.items[0].CarParkID;
                    }
                }

                //this.operator.itemsFull = dt;

                this.carpark.loader = false;
                this.carpark.disable = false;
            }

            catch(err){
                console.log(err);
            }

            return list;
        },

        validateAccess(val){
            let access = JSON.parse(JSON.stringify(this.$store.getters.getAccess))
            return rule.checkaccess(val, access);
        },

        viewMore(row, flag){
            //this.autoRefresh = 0;
            if (flag == 'delete'){
                this.ma.modal_status = true;
                this.ma.modalInfo = row;
            }

            if (flag == 'create'){
                this.ma.modal = true;
                this.ma.allInfo = this.items;
            }

            if (flag == 'update'){
                this.ma.modal_update = true;
                this.ma.modalInfo = row;
                this.ma.allInfo = this.items;
                this.ma.readonly = false;
            }

            if (flag == 'view'){
                this.ma.modal_update = true;
                this.ma.modalInfo = row;
                this.ma.allInfo = this.items;
                this.ma.readonly = true;
            }

            if (flag == 'view_pdf'){
                this.ma.modal_pdf = true;
                this.ma.modalInfo = row;
                this.pdfurl = row.InvoiceReceiptURL;
            }

            if (flag == 'view_details'){
                this.ma.modal_viewdetails = true;
                this.ma.modalInfo = row;
                this.ma.allInfo = this.items;
                this.ma.readonly = true;
            }

            this.ma.customText = {text1: "Gate ID", text2: "Name"};
            this.ma.modal_action_type = flag;
        },

        update(row){
            this.ma.modalInfo = row;
            this.$router.push({ name: "Update", params: {id: row.ValuePassProductID}});
        },

        onClosed(value){
            console.log(value)
            if (value == false){
                this.ma.modal = value;
                this.ma.modal_status = value;
                this.ma.modal_update = value;
                this.ex.modal = value;
                this.ma.modal_pdf = value;
                this.ma.modal_viewdetails = value;
            }
        },

        /*
        onConfirm(value){
            console.log(value)
            if (value.flag === 'create'){
                this.ma.confirmCreate = true;
                this.ma.modal = false;
                this.addSystem(value);
            }

            if (value.flag === 'update'){
                this.ma.modal_update = false;
                this.systemUpdate(value);
            }

            if (value === true){
                if (this.ma.modal_action_type == 'delete')
                {
                    this.ma.modal_status = false;
                    this.systemRemove();
                }
            }
        },
        */

        async execFilter(type){
            if (type == 'system'){
                await this.getOperatorFull();
                await this.getCarParkFull();
                await this.searchTimeOut();
            }

            if (type == 'operator'){
                await this.getCarParkFull();
                await this.searchTimeOut();
            }

            if (type == 'carpark'){
                await this.searchTimeOut();
            }

            if (type == 'merchantcode'){
                await this.searchTimeOut();
            }

            if (type == 'displaystatus'){
                this.displaystatus.counter++;
                if (this.displaystatus.counter % 3 == 0){
                    this.displaystatus.rerender++;
                    this.displaystatus.selected = null;
                    this.displaystatus.label = "All Mobile Display Status";
                }

                if (this.displaystatus.selected === true){
                    this.displaystatus.label = "Mobile Display Status Enable";
                }

                if (this.displaystatus.selected === false){
                    this.displaystatus.label = "Mobile Display Status Disable";
                }
                await this.searchTimeOut();
            }
        },

        searchTimeOut() {

          clearTimeout(this.timeout);

          // Make a new timeout set to go off in 800ms
          this.timeout = setTimeout(() => {
          this.listItems(1);
          }, 800);
        },

        horizontalScroll(){
            var wrapper1 = document.getElementById('wrapper1');
            var wrapper2 = document.querySelector('.v-data-table__wrapper');
            document.getElementById('wrapper1').style.width = `${String(document.getElementsByClassName("v-data-table__wrapper")[0].offsetWidth)}px`;
            document.getElementById('div1').style.width = `${String(document.getElementsByTagName("table")[0].offsetWidth)}px`;

            if (document.getElementsByClassName("v-data-table-header")[0].offsetWidth < document.getElementById("table-parent").offsetWidth)
                    document.getElementById('wrapper1').style.display = "none";

                else
                    document.getElementById('wrapper1').style.display = "block";

            window.addEventListener('resize', function() {
                document.getElementById('wrapper1').style.width = `${String(document.getElementsByClassName("v-data-table__wrapper")[0].offsetWidth)}px`;
                document.getElementById('div1').style.width = `${String(document.getElementsByTagName("table")[0].offsetWidth)}px`;

                if (document.getElementsByClassName("v-data-table-header")[0].offsetWidth < document.getElementById("table-parent").offsetWidth)
                    document.getElementById('wrapper1').style.display = "none";

                else
                    document.getElementById('wrapper1').style.display = "block";
            });

            const myObserver = new ResizeObserver(entries => {
            entries.forEach(entry => {
                document.getElementById('wrapper1').style.width = `${String(document.getElementsByClassName("v-data-table__wrapper")[0].offsetWidth)}px`;
                document.getElementById('div1').style.width = `${String(document.getElementsByTagName("table")[0].offsetWidth)}px`;
                console.log('table', document.getElementsByClassName("v-data-table__wrapper")[0].offsetWidth);
                console.log('parent', document.getElementById("table-parent").offsetWidth);

                if (document.getElementsByClassName("v-data-table-header")[0].offsetWidth < document.getElementById("table-parent").offsetWidth)
                    document.getElementById('wrapper1').style.display = "none";

                else
                    document.getElementById('wrapper1').style.display = "block";
            });
            });

            const someEl = document.getElementsByTagName("table")[0];
            myObserver.observe(someEl);


            wrapper1.onscroll = function() {
            wrapper2.scrollLeft = wrapper1.scrollLeft;
            };
            wrapper2.onscroll = function() {
            wrapper1.scrollLeft = wrapper2.scrollLeft;
            };
        },

        setTime(){
            let dte = new Date();
            let starttime = this.starttime.item.start !== '' ? moment(new Date(this.starttime.item.start).setHours(16, 0, 0, 0)).subtract(1, 'days').format('YYYY-MM-DD HH:mm:ss') : moment(new Date(moment(new Date()).subtract(31, 'days')).setHours(16, 0, 0, 0)).subtract(1, 'days').format('YYYY-MM-DD HH:mm:ss');
            let endtime = this.starttime.item.end !== '' ? moment(new Date(this.starttime.item.end).setHours(15, 59, 59, 0)).format('YYYY-MM-DD HH:mm:ss') : moment(new Date().setHours(15, 59, 59, 0)).format('YYYY-MM-DD HH:mm:ss');
            if (this.starttime.item.start == ""){
                this.starttime.item.start = new Date(moment(starttime).add(1, 'days'));
                this.starttime.item.end = new Date(endtime);
            }
            console.log('starttime', starttime);
            console.log('endtime', endtime);
            this.starttimeforapi = starttime;
            this.endtimeforapi = endtime;

            // Add a day
            let dte2 = new Date(moment(endtime).add(1, 'days'))
            console.log('dte', new Date(moment(endtime).add(1, 'days')));
            this.dayplusone = null
        },

        cleardayplusone(v){
            let dte2 = new Date(moment(v.start).add(31, 'days'));
            this.dayplusone = [
                        {
                        start: dte2,
                        end: null
                        },
                    ];
        },
    }
}
</script>